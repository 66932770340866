import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useInputFilter } from "../context/FilterContext";
import { useNavigate } from "react-router-dom";

const SearchForm = () => {
  const [inputSearchTerm, setSearchTerm] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const { inputFilterDispatch } = useInputFilter();
  const navigate = useNavigate();

  const iconsPath = process.env.PUBLIC_URL + "/icons/";

  const locationOptions = ["Islamabad", "Lahore", "Rawalpindi", "Muzaffarabad"];

  const handleSearch = async () => {
    try {
      // // Format the date to 'yyyy-MM-dd' without converting to ISO string
      // const formattedDate =
      //   selectedDate !== null && selectedDate !== undefined
      //     ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
      //         .toString()
      //         .padStart(2, "0")}-${selectedDate
      //         .getDate()
      //         .toString()
      //         .padStart(2, "0")
      //         .toString()}`
      //     : null;
      // setSelectedDate(formattedDate);
      // const response = await axios.get("http://localhost:5000/shared/search", {
      //   params: {
      //     searchTerm: inputSearchTerm,
      //     date: formattedDate, // Use formatted date here
      //   },
      // });
      // const searchData = response.data;
      // console.log("Search results:", searchData);
      // Dispatch your filter action here if needed
      inputFilterDispatch({
        payload: { inputSearchTerm, zipCode, selectedDate },
      });
      navigate("/discover");
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="search-container">
      <div className="search-form">
        <div className="input-container">
          <img src={iconsPath + "search.svg"} alt="Search icon" />
          <input
            type="text"
            placeholder="Search by Event, Artist, Venue"
            value={inputSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="input-container">
          <img src={iconsPath + "map-pin-blue.svg"} alt="Map pin icon" />
          <select
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            onKeyDown={handleKeyDown}
            className={zipCode === "" ? "dropdown-empty" : "dropdown"}
          >
            <option value="" className="default-option">
              Select City
            </option>
            {locationOptions.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <div className="date">
            <img src={iconsPath + "calendar-blue.svg"} alt="Calendar icon" />
            <DatePicker
              className="date-picker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Date"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <button
          type="submit"
          className="search-button"
          onClick={handleSearch}
          onKeyDown={handleKeyDown}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchForm;
