import React, { useEffect, useState } from "react";
import axios from "axios";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";

const AboutUs = () => {
    const [stats, setStats] = useState([{ totalEventCount: 0 }, { activeEventCount: 0 }]);
    const [trendingData, setTrendingData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
  
    useEffect(() => {
      axios.get(`${apiUrl}/home/active-events-count`).then((response) => {
        axios.get(`${apiUrl}/home/total-events`).then((response2) => {
          setStats([
            { totalEventCount: response2.data.EventCount },
            { activeEventCount: response.data.activeEventCount },
          ]);
        });
      });
    }, [apiUrl]);
  
    useEffect(() => {
      axios
        .get(`${apiUrl}/home/trending-events`)
        .then((response) => {
          setTrendingData(response.data || []); // Fallback to empty array
        })
        .catch((error) => {
          console.error("Error fetching trending data:", error);
          setTrendingData([]); // Fallback to empty array
        });
    }, [apiUrl]);
  return (
    <>
      <Header searchBar={false} />
      <div className="about-us-page">
        {/* Hero Section */}
        <section className="relative bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-16 px-4">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              About <span className="text-yellow-300">Us</span>
            </h1>
            <p className="text-lg md:text-xl leading-relaxed max-w-3xl mx-auto">
              We bring communities together through unique and innovative event experiences. 
              Discover the best events happening near you and create unforgettable memories.
            </p>
          </div>
        </section>

        {/* Banner Section */}
        <section className="py-12 px-4">
          <div className="container mx-auto">
            <Banner
              subheading="Empowering Communities Through Events"
              stats={stats}
              data={trendingData} // Trending data passed here
            />
          </div>
        </section>

        {/* Mission and Vision */}
        <section className="bg-gray-100 py-16 px-4">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-8">
              Our <span className="text-purple-600">Mission</span> &{" "}
              <span className="text-yellow-500">Vision</span>
            </h2>
            <p className="text-lg md:text-xl leading-relaxed text-gray-700 max-w-4xl mx-auto">
              Our mission is to inspire and connect people by simplifying event
              discovery and creating exceptional experiences. Our vision is a
              world where people can come together effortlessly to celebrate and
              explore life’s moments.
            </p>
          </div>
        </section>

        {/* Call to Action */}
        <section className="py-16 bg-gradient-to-r from-indigo-600 to-purple-700 text-white">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Join Us in Discovering Amazing Events
            </h2>
            <p className="text-lg md:text-xl mb-8">
              Be part of our growing community and explore events near you.
            </p>
            <button
              className="px-8 py-3 bg-yellow-400 text-black font-semibold rounded-full shadow-lg hover:bg-yellow-500 hover:shadow-xl transition-transform transform hover:scale-105"
              onClick={() => (window.location.href = "/home")}
            >
              Explore Now
            </button>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
